import { FormProps } from "@rjsf/core";
import { Field, RJSFValidationError } from "@rjsf/utils";
import { TFunction } from "i18next";
import { JSONSchema7 } from "json-schema";
import isObject from "lodash/isObject";
import transform from "lodash/transform";
import { getI18n } from "react-i18next";

import ApiUpdateWidget from "./ApiUpdateWidget";
import { CustomBusinessFilterField } from "./CustomBusinessFilterField";
import CustomCheckbox from "./CustomCheckbox";
import CustomColorPicker from "./CustomColorPicker";
import CustomCSSUnit from "./CustomCSSUnit";
import CustomDate from "./CustomDate";
import CustomEditor from "./CustomEditor";
import CustomEmptyLabelField from "./CustomEmptyLabelField";
import CustomEnumTranslation from "./CustomEnumTranslation";
import CustomFieldHiddenObject from "./CustomFieldHiddenObject";
import CustomFieldId from "./CustomFieldId";
import CustomFieldsSelectWidget from "./CustomFieldsSelectWidget";
import CustomFieldsValidForSearchSelectWidget from "./CustomFieldsValidForSearchSelectWidget";
import CustomFolderWidget from "./CustomFolderField";
import CustomFontUploadField from "./CustomFontUploadField";
import CustomFooterLinksField from "./CustomFooterLinksField";
import CustomFormsSelectWidget from "./CustomFormsSelectWidget";
import CustomFrontEditLabelWidget from "./CustomFrontEditLabelWidget";
import CustomGdprBackground from "./CustomGdprBackground";
import CustomImageUrlField from "./CustomImageUrlField";
import CustomLanguageSelectorWidget from "./CustomLanguageSelectorWidget";
import CustomMailLink from "./CustomMailLink";
import CustomMenuLinksField from "./CustomMenuLinksField";
import CustomMosaicElementField from "./CustomMosaicElementField";
import { CustomMosaicWrapper } from "./CustomMosaicWrapper";
import CustomMultilingualField from "./CustomMultilingualField";
import CustomNumberWidget from "./CustomNumberWidget";
import CustomSelectFontWidget from "./CustomSelectFontWidget";
import CustomSelectResource from "./CustomSelectResource";
import CustomThemeColorPicker from "./CustomThemeColorPicker";
import { CustomTranslationWidget } from "./CustomTranslationWidget";
import CustomWidgetId from "./CustomWidgetId";
import CustomWidgetKHeader from "./CustomWidgetKHeader";
import CustomWidgetNews from "./CustomWidgetNews";
import CustomWidgetSelector from "./CustomWidgetSelector";
import CustomWidgetUrlText from "./CustomWidgetUrlText";
import CustomWysiwyg from "./CustomWysiwyg";

/**
 * Adapt JSON Schema for i18n :
 * - Find "i18n-*" key in jsonschema, and put in value the associated translation.
 * - Rename "i18n-*" key to delete "i18n-" in order to be compatible for "rjsf" library
 */
const i18nStartKey = "i18n-";
export const i18nJsonSchema = function (jsonSchema: any, t: TFunction): JSONSchema7 {
  return transform(jsonSchema, function (result: JSONSchema7, value, key) {
    let adaptedKey = key;
    let adaptedValue = value;
    if (typeof adaptedKey === "string" && adaptedKey.startsWith(i18nStartKey)) {
      adaptedKey = adaptedKey.slice(i18nStartKey.length);
      adaptedValue = t(`admin:${value}`);
    }
    result[adaptedKey] = isObject(adaptedValue) ? i18nJsonSchema(adaptedValue, t) : adaptedValue;
  });
};

export const customWidgets: FormProps<any>["widgets"] = {
  colorClassic: CustomColorPicker,
  color: CustomThemeColorPicker,
  wysiwyg: CustomWysiwyg,
  editor: CustomEditor,
  date: CustomDate,
  customNumber: CustomNumberWidget,
  fields: CustomFieldsSelectWidget,
  fieldsValidForSearch: CustomFieldsValidForSearchSelectWidget,
  folders: CustomFolderWidget,
  fieldId: CustomFieldId,
  widgetId: CustomWidgetId,
  customCheckbox: CustomCheckbox,
  customWidgetUrlText: CustomWidgetUrlText,
  customFormsSelectWidget: CustomFormsSelectWidget,
  customFrontEditLabelWidget: CustomFrontEditLabelWidget,
  customTranslationWidget: CustomTranslationWidget,
  customLanguageSelectorWidget: CustomLanguageSelectorWidget,
  customCSSUnit: CustomCSSUnit,
  customSelectFont: CustomSelectFontWidget,
  apiUpdate: ApiUpdateWidget,
  enumTranslation: CustomEnumTranslation,
};

export const customFields: FormProps<any>["fields"] = {
  widgetSelector: CustomWidgetSelector,
  resourceSelect: CustomSelectResource,
  hiddenObject: CustomFieldHiddenObject,
  customImageUrlField: CustomImageUrlField as Field,
  customFooterLinksField: CustomFooterLinksField as Field,
  customMenuLinksField: CustomMenuLinksField as Field,
  customEmptyLabelField: CustomEmptyLabelField,
  mosaicElement: CustomMosaicElementField,
  customMosaicWrapper: CustomMosaicWrapper,
  customFontUpload: CustomFontUploadField,
  customWidgetNews: CustomWidgetNews,
  customWidgetKHeader: CustomWidgetKHeader,
  customMultilingualField: CustomMultilingualField,
  customGdprBackground: CustomGdprBackground,
  customBusinessFilterField: CustomBusinessFilterField,
  customMailLink: CustomMailLink,
};

/**
 * Transform errors (applied at field level only)
 * Waiting for a better way to achieve label update: https://github.com/rjsf-team/react-jsonschema-form/issues/2718
 */
export const transformErrors = function (errors: RJSFValidationError[]) {
  return errors.map((error) => {
    if (
      error.name === "type" &&
      error.params.type === "number" &&
      error.property?.includes("folderIds")
    ) {
      error.message = getI18n().t("admin:folderSelectWidget.empty.error") as string;
    } else if (
      error.name === "required" &&
      error.property?.includes("name") &&
      error.property?.includes("fonts.resources")
    ) {
      error.message = getI18n().t("admin:fontResource.name.empty.error") as string;
    }
    return error;
  });
};
