/* eslint-disable @next/next/no-img-element */
import React, { useCallback, useState } from "react";

import { currentLocaleAtom, sheetFieldsSelector } from "@keepeek/commons";
import { SanitizeHtml } from "@keepeek/refront-components";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import { Field, FieldProps } from "@rjsf/utils";
import { isBoolean } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import advancedExample from "../../../../../public/images/admin/advanced_filter_example.png";
import multivaluedExample from "../../../../../public/images/admin/multivalued_filter_example.png";
import sectionExample from "../../../../../public/images/admin/section_example.png";
import standardExample from "../../../../../public/images/admin/standard_filter_example.png";
import { KIcon } from "../../../../components/common/KIcon";
import TreeEdit from "../../../../containers/TreeEdit";
import { TreeEditOption } from "../../../../containers/TreeEdit/types";
import { BusinessFilterFilter } from "../../../../models/configuration/components/businessFilter";
import { ManageFilterDetail } from "./ManageFilterDetail";
import { NoFilter } from "./NoFilter";
import { getOptionLabel } from "./utils";

export const DRAG_N_DROP_WIDTH = 400;
export const CustomBusinessFilterField: Field<BusinessFilterFilter[]> = function (
  props: FieldProps<Partial<BusinessFilterFilter>[]>,
) {
  const { t } = useTranslation("admin");
  const getCurrentLocaleAtom = useRecoilValue(currentLocaleAtom);
  const sheetFields = useRecoilValue(sheetFieldsSelector);
  const [editFilterData, setEditFilterData] = useState<
    | {
        businessFilterFilter: Partial<BusinessFilterFilter>;
        rootIndex?: number;
        childIndex?: number;
        ref?: HTMLElement;
      }
    | undefined
  >();

  const handleFilterChange = (data?: Partial<BusinessFilterFilter>) => {
    if (!editFilterData) {
      return;
    }
    if (data) {
      const isTypeUpdated = data.type !== editFilterData.businessFilterFilter.type;
      setEditFilterData({
        ...editFilterData,
        businessFilterFilter: {
          ...data,
          // clear filter name if filter type change
          ...(isTypeUpdated && { name: undefined }),
        },
      });
      if (isTypeUpdated) {
        data.name = undefined;
      }
    }
    handleChange(false, data, editFilterData.rootIndex, editFilterData.childIndex);
  };

  const handleChange = (
    addNewItem: boolean,
    data?: Partial<BusinessFilterFilter>,
    rootIndex?: number,
    childIndex?: number,
  ) => {
    const mergedFormData: Partial<BusinessFilterFilter>[] = cloneDeep(props.formData) || [];
    if (rootIndex !== undefined && childIndex === undefined && mergedFormData[rootIndex]) {
      // Update/delete root filter or section
      if (data && !addNewItem) {
        mergedFormData[rootIndex] = data;
      } else if (data && addNewItem) {
        mergedFormData.splice(rootIndex + 1, 0, data);
      } else if (!data) {
        mergedFormData.splice(rootIndex, 1);
      }
    } else if (
      rootIndex !== undefined &&
      childIndex !== undefined &&
      "filters" in mergedFormData[rootIndex]
    ) {
      // Update/delete child filter
      const filters: Partial<BusinessFilterFilter>[] = mergedFormData[rootIndex].filters || [];
      if (data && !addNewItem) {
        filters[childIndex] = data;
      } else if (data && addNewItem) {
        filters.splice(childIndex + 1, 0, data);
      } else if (!data) {
        filters.splice(childIndex, 1);
      }
    } else if (data && rootIndex !== undefined && !mergedFormData[rootIndex]) {
      // Create root filter/section
      mergedFormData[rootIndex] = data;
    }
    props.onChange(mergedFormData);
  };

  const items: TreeEditOption<Partial<BusinessFilterFilter>>[] =
    props.formData?.map<TreeEditOption<Partial<BusinessFilterFilter>>>((rootFilter, indexRoot) => {
      return {
        id: `${indexRoot}`,
        label: getOptionLabel(rootFilter, getCurrentLocaleAtom, sheetFields),
        data: rootFilter,
        children: rootFilter.filters?.map((childFilter, indexChild) => {
          return {
            id: `${indexRoot}-${indexChild}`,
            label: getOptionLabel(childFilter, getCurrentLocaleAtom, sheetFields),
            data: childFilter,
          };
        }),
      };
    }) || [];
  const handleFilterEdit = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      option: TreeEditOption<Partial<BusinessFilterFilter>>,
    ) => {
      if (!option.data) {
        return;
      }
      const rootIndex = !isNaN(parseInt(option.id.split("-")[0]))
        ? parseInt(option.id.split("-")[0])
        : undefined;
      const childIndex = !isNaN(parseInt(option.id.split("-")[1]))
        ? parseInt(option.id.split("-")[1])
        : undefined;
      setEditFilterData({
        businessFilterFilter: option.data,
        rootIndex,
        childIndex,
        ref: event.currentTarget,
      });
    },
    [],
  );
  const handleFiltersChange = useCallback(
    (filters: TreeEditOption<Partial<BusinessFilterFilter>>[]) => {
      const ret: Partial<BusinessFilterFilter>[] = [];
      filters.forEach((f) => {
        if (f.data) {
          // Here we must carry out a transformation in order to recover the correct
          // sorting value when it comes to a section of filters.
          // This value is available in the “data” reported by the TreeEdit component.
          // I was unable to correct this problem directly in the TreeEdit component
          // (because it is a generic approach and it is not aware of the structure of the filters/section)
          // and this is why the transformation is done here :(
          if (f.data.filters && f.children) {
            f.data.filters = f.children
              .filter((child) => child.data)
              .map((child) => child.data) as Partial<BusinessFilterFilter>[];
          }
          ret.push(f.data);
        }
      });
      props.onChange(ret);
    },
    [props],
  );
  const handleAddFilter = (previousIndex?: number, childIndex?: number) => {
    handleChange(true, {}, previousIndex ? previousIndex : 0, childIndex);
    setEditFilterData(undefined);
  };
  const handleAddSection = (previousIndex?: number) => {
    handleChange(
      true,
      {
        title: [],
        filters: [],
      },
      previousIndex ? previousIndex : 0,
    );
    setEditFilterData(undefined);
  };

  // close filter edit when toggle, remove and drag:
  const handleToggle = () => setEditFilterData(undefined);
  const handleRemove = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: TreeEditOption<Partial<BusinessFilterFilter>>,
  ) => {
    const rootIndex = !isNaN(parseInt(item.id.split("-")[0]))
      ? parseInt(item.id.split("-")[0])
      : undefined;
    const childIndex = !isNaN(parseInt(item.id.split("-")[1]))
      ? parseInt(item.id.split("-")[1])
      : undefined;
    setEditFilterData(undefined);
    handleChange(false, undefined, rootIndex, childIndex);
  };
  const handleDragStart = () => setEditFilterData(undefined);
  return (
    <Box>
      <Accordion
        sx={{ marginBottom: (theme) => theme.spacing(3), marginTop: (theme) => theme.spacing(-3) }}
        onChange={() => setEditFilterData(undefined)}
      >
        <AccordionSummary
          expandIcon={<KIcon baseClassName="fas" className="fa-regular fa-chevron-down"></KIcon>}
        >
          <KIcon
            baseClassName="fas"
            className="fa-regular fa-circle-info"
            color="primary"
            sx={{ padding: (theme) => theme.spacing(1) }}
          ></KIcon>
          <Typography>{t("rjsf.business-filter.information-panel.header")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
            {t("rjsf.business-filter.information-panel.detail.section.title")}
          </Typography>
          <Typography>
            {t("rjsf.business-filter.information-panel.detail.section.description")}
          </Typography>
          <Typography sx={{ textDecoration: "underline" }}>
            {t("rjsf.business-filter.information-panel.detail.section.example")}
          </Typography>
          <img src={sectionExample.src} alt="Example of the section" />
          <Typography
            sx={{ fontWeight: "bold", fontSize: "18px", marginTop: (theme) => theme.spacing(2) }}
          >
            {t("rjsf.business-filter.information-panel.detail.filter.title")}
          </Typography>
          <Typography variant="body1" component="div">
            <SanitizeHtml
              html={t("rjsf.business-filter.information-panel.detail.filter.description")}
            />
            <SanitizeHtml
              html={t("rjsf.business-filter.information-panel.detail.filter.standard")}
            />
            <img src={standardExample.src} alt="Example of the section" />
            <SanitizeHtml
              html={t("rjsf.business-filter.information-panel.detail.filter.multivalued")}
            />
            <img src={multivaluedExample.src} alt="Example of the section" />
            <SanitizeHtml
              html={t("rjsf.business-filter.information-panel.detail.filter.advanced")}
            />
            <img src={advancedExample.src} alt="Example of the section" />
          </Typography>
        </AccordionDetails>
      </Accordion>
      {items.length === 0 && (
        <NoFilter onAddFilter={handleAddFilter} onAddSection={handleAddSection} />
      )}
      <ManageFilterDetail
        {...(props as unknown as FieldProps<BusinessFilterFilter>)}
        formData={editFilterData?.businessFilterFilter}
        rootIndex={editFilterData?.rootIndex}
        schema={props.schema.items && !isBoolean(props.schema.items) ? props.schema.items : {}}
        ref={editFilterData?.ref}
        onChange={(data) => handleFilterChange(data)}
      />
      <Box
        sx={{
          width: `${DRAG_N_DROP_WIDTH}px`,
        }}
      >
        <TreeEdit<Partial<BusinessFilterFilter>>
          items={items}
          onEdit={handleFilterEdit}
          allowRemove={true}
          onChange={handleFiltersChange}
          KIcon={KIcon}
          onToggle={handleToggle}
          onRemove={handleRemove}
          onDragStart={handleDragStart}
          onAddParentItem={handleAddSection}
          onAddItem={handleAddFilter}
        />
      </Box>
    </Box>
  );
};
